import { RouteType } from 'leeloo-react/model/RouteType';

import { CategoryEditAddPage } from '../components/pages/admin/category/CategoryEditAddPage';
import { CategoryListPage } from '../components/pages/admin/category/CategoryListPage';
import { CategoryPage } from '../components/pages/admin/category/CategoryPage';
import { DashboardAdminPage } from '../components/pages/admin/dashboard/DashboardAdminPage';
import { DashboardPage } from '../components/pages/dashboard/DashboardPage';
import { ELearningListPage } from '../components/pages/admin/eLearning/ELearningListPage';
import { UpdateScore } from '../components/pages/admin/eLearning/UpdateScore';
import { LearnerELearningListPage } from '../components/pages/admin/learner/LearnerELearningListPage';
import { LearnerOnSiteListPage } from '../components/pages/admin/learner/LearnerOnSiteListPage';
import { OnSiteListPage } from '../components/pages/admin/onSite/OnSiteListPage';
import { ParticipantOnSiteManagePage } from '../components/pages/admin/participant/ParticipantOnSiteManagePage';
import { ParticipantWebinarManagePage } from '../components/pages/admin/participant/ParticipantWebinarManagePage';
import { ProviderListPage } from '../components/pages/admin/provider/ProviderListPage';
import { SessionListPage } from '../components/pages/admin/sessions/SessionListPage';
import { SkillListPage } from '../components/pages/admin/skill/SkillListPage';
import { ThematicAdminPage } from '../components/pages/admin/thematic/ThematicAdminPage';
import { ThematicEditAddPage } from '../components/pages/admin/thematic/ThematicEditAddPage';
import { ThematicListPage } from '../components/pages/admin/thematic/ThematicListPage';
import { TrainerListPage } from '../components/pages/admin/trainer/TrainerListPage';
import { TrainingPlaceListPage } from '../components/pages/admin/trainingPlace/TrainingPlaceListPage';
import { User } from '../components/pages/admin/user/User';
import {
  TrainingRequest,
} from '../components/pages/admin/trainingRequest/TrainingRequest';
import { Webinar } from '../components/pages/admin/webinar/Webinar';
import { ValidateTerms } from '../components/pages/auth/ValidateTerms';
import { ELearningPage } from '../components/pages/eLearning/ELearningPage';
import NotAccessPage from '../components/pages/errors/NotAccessPage';
import { HomePage } from '../components/pages/home/HomePage';
import { MaintenancePage } from '../components/pages/maintenance/MaintenancePage';
import { OnSitePage } from '../components/pages/onSite/OnSitePage';
import { ProfilePage } from '../components/pages/profil/ProfilePage';
import { BotPage } from '../components/pages/search/BotPage';
import { SearchPage } from '../components/pages/search/SearchPage';
import { ThematicPage } from '../components/pages/thematic/ThematicPage';
import {
  ThematicStartedPage,
} from '../components/pages/thematic/ThematicStartedPage';
import { ThematicProfilePage } from '../components/pages/thematic/ThematicProfilePage';
import LoadedPage from '../components/tools/LoadedPage';
import RoleUserEnum from '../enum/RoleUserEnum';
import { RouteEnum } from '../enum/RouteEnum';
// @TODO passer toutes ces vues en tsx
import CompetencesElearning
  from '../views/admin/forms/e-learning/chapitre/CompetencesElearning';
import CreateChapitreElearning
  from '../views/admin/forms/e-learning/chapitre/CreateChapitreElearning';
import CreateUniteDeFormation
  from '../views/admin/forms/e-learning/chapitre/CreateUniteDeFormation';
import CreateElearning
  from '../views/admin/forms/e-learning/crud/CreateElearning';
import UpdateElearning
  from '../views/admin/forms/e-learning/crud/UpdateElearning';
import CreateFaceSession
  from '../views/admin/forms/face-training/crud/CreateFaceSession';
import CreateFaceSession1
  from '../views/admin/forms/face-training/crud/CreateFaceSession1';
import CreateFaceSession2
  from '../views/admin/forms/face-training/crud/CreateFaceSession2';
import CreateFaceTraining
  from '../views/admin/forms/face-training/crud/CreateFaceTraining';
import ShowSessions from '../views/admin/forms/face-training/crud/ShowSessions';
import UpdateFaceTraining
  from '../views/admin/forms/face-training/crud/UpdateFaceTraining';
import UpdateSession
  from '../views/admin/forms/face-training/crud/UpdateSession';
import CreateFormer from '../views/admin/forms/formateur/CreateFormer';
import ReadFormers from '../views/admin/forms/formateur/ReadFormers';
import UpdateFormer from '../views/admin/forms/formateur/UpdateFormer';
import InfoApprenant
  from '../views/admin/forms/formationelearning/apprenant/InfoApprenant';
import ElearningShow
  from '../views/admin/forms/formationelearning/ElearningShow';
import LocationForm from '../views/admin/forms/location/LocationForm';
import LocationShow from '../views/admin/forms/location/LocationShow';
import LocationUpdate from '../views/admin/forms/location/LocationUpdate';
import CreatePrestataire from '../views/admin/forms/prestataire/CreateProvider';
import ProviderEdit from '../views/admin/forms/prestataire/ProviderEdit';
import ReadProvider from '../views/admin/forms/prestataire/ReadProvider';
import UpdateSettings from '../views/admin/forms/settings/crud/UpdateSettings';
import Competence from '../views/competence/Competence';
import Createcompetence from '../views/competence/Createcompetence';
import Updatecompetence from '../views/competence/Updatecompetence';
import DetailFormation from '../views/detailFormation/DetailFormation';
import DetailsFormationE from '../views/detailFormation/DetailsFormationE';
import Email from '../views/email/Email';
import ShowAction from '../views/formationPresentiel/ShowAction';
import CreateWebinair from '../views/webinair/CreateWebinair';
import DetailsWebinar from '../views/webinair/DetailsWebinarFront';
import DetailWebinar from '../views/webinair/DetailWebinar';
import UpdateWebinair1 from '../views/webinair/UpdateWebinair1';

/**
 * Configuration des routes exprimé ici
 */
export const RouteConfig: RouteType[] = [
  /* ----------------- *
    *       COMMONS      *
    * ------------------ */
  {
    label: 'Nothing habilities',
    routeUrl: RouteEnum.NOT_ACCESS,
    pageComponent: NotAccessPage,
  },
  {
    label: 'Validation CGU',
    routeUrl: RouteEnum.TERMS_VALIDATE,
    pageComponent: ValidateTerms,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Loading...',
    routeUrl: RouteEnum.LOADED,
    pageComponent: LoadedPage,
  },
  /* ----------------- *
    *        ADMIN       *
    * ------------------ */
  {
    label: 'Dashboard',
    routeUrl: RouteEnum.ADMIN_DASHBOARD,
    // pageComponent: DashboardAdmin,
    pageComponent: DashboardAdminPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Détail utilisateur
  {
    label: 'Admin - User - Détail',
    routeUrl: RouteEnum.ADMIN_LEARNER_SHOW,
    pageComponent: InfoApprenant,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - eUser - Détail',
    routeUrl: RouteEnum.ADMIN_ELEARNER_SHOW,
    pageComponent: InfoApprenant,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // E-Learning
  {
    label: 'Actions de formation',
    routeUrl: RouteEnum.ADMIN_E_LEARNING,
    pageComponent: ELearningListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - E-Learning - Détail',
    routeUrl: RouteEnum.ADMIN_E_LEARNING_SHOW,
    pageComponent: ElearningShow,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - E-Learning - Ajout',
    routeUrl: RouteEnum.ADMIN_E_LEARNING_ADD,
    pageComponent: CreateElearning,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - E-Learning - Ajout step 2',
    routeUrl: RouteEnum.ADMIN_E_LEARNING_ADD_STEP_2,
    pageComponent: CreateChapitreElearning,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - E-Learning - Ajout step 2.5',
    routeUrl: RouteEnum.ADMIN_E_LEARNING_ADD_STEP_2_5,
    pageComponent: CreateUniteDeFormation,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - E-Learning - Ajout step 3',
    routeUrl: RouteEnum.ADMIN_E_LEARNING_ADD_STEP_3,
    pageComponent: CompetencesElearning,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - E-Learning - Edition',
    routeUrl: RouteEnum.ADMIN_E_LEARNING_EDIT,
    pageComponent: UpdateElearning,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - E-Learning - update score',
    routeUrl: RouteEnum.E_LEARNING_UPDATE_SCORE,
    pageComponent: UpdateScore,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },

  // Apprenants
  {
    label: 'Apprenants',
    routeUrl: RouteEnum.ADMIN_E_LEARNING_LEARNER,
    pageComponent: LearnerELearningListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },

  // Formations présentiels
  {
    label: 'Actions de formation',
    routeUrl: RouteEnum.ADMIN_ON_SITE,
    pageComponent: OnSiteListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Détail',
    routeUrl: RouteEnum.ADMIN_ON_SITE_SHOW,
    pageComponent: ShowAction,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Ajout',
    routeUrl: RouteEnum.ADMIN_ON_SITE_ADD,
    pageComponent: CreateFaceTraining,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Ajout step 2',
    routeUrl: RouteEnum.ADMIN_ON_SITE_ADD_STEP_2,
    pageComponent: CreateFaceSession,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Ajout step 3',
    routeUrl: RouteEnum.ADMIN_ON_SITE_ADD_STEP_3,
    pageComponent: CreateFaceSession2,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Edition',
    routeUrl: RouteEnum.ADMIN_ON_SITE_EDIT,
    pageComponent: UpdateFaceTraining,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },

  // sessions de formation
  {
    label: 'Sessions de formation',
    routeUrl: RouteEnum.ADMIN_SESSION,
    pageComponent: SessionListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Sessions de formation - Création',
    routeUrl: RouteEnum.ADMIN_SESSION_ADD,
    pageComponent: CreateFaceSession1,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Sessions de formation - Création',
    routeUrl: RouteEnum.ADMIN_SESSION_ADD_TRAINING,
    pageComponent: CreateFaceSession1,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Sessions de formation - Détail',
    routeUrl: RouteEnum.ADMIN_SESSION_SHOW,
    pageComponent: ShowSessions,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Sessions de formation - Edition',
    routeUrl: RouteEnum.ADMIN_SESSION_EDIT,
    pageComponent: UpdateSession,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Participants
  {
    label: 'Participants',
    routeUrl: RouteEnum.ADMIN_ON_SITE_PARTICIPANT,
    pageComponent: LearnerOnSiteListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formations Présentielles - Sessions de formation - Ajouter participant',
    routeUrl: RouteEnum.ADMIN_ON_SITE_PARTICIPANT_MANAGE,
    pageComponent: ParticipantOnSiteManagePage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Webinars - Participants',
    routeUrl: RouteEnum.ADMIN_WEBINAR_PARTICIPANT_MANAGE,
    pageComponent: ParticipantWebinarManagePage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Formateurs
  {
    label: 'Formateurs',
    routeUrl: RouteEnum.ADMIN_TRAINER,
    pageComponent: TrainerListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formateurs - Ajout',
    routeUrl: RouteEnum.ADMIN_TRAINER_ADD,
    pageComponent: CreateFormer,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formateurs - Détail',
    routeUrl: RouteEnum.ADMIN_TRAINER_SHOW,
    pageComponent: ReadFormers,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formateurs - Edition',
    routeUrl: RouteEnum.ADMIN_TRAINER_EDIT,
    pageComponent: UpdateFormer,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Prestataires
  {
    label: 'Prestataires',
    routeUrl: RouteEnum.ADMIN_PROVIDER,
    pageComponent: ProviderListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formateurs - Ajout',
    routeUrl: RouteEnum.ADMIN_PROVIDER_ADD,
    pageComponent: CreatePrestataire,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formateurs - Détail',
    routeUrl: RouteEnum.ADMIN_PROVIDER_SHOW,
    pageComponent: ReadProvider,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Formateurs - Edition',
    routeUrl: RouteEnum.ADMIN_PROVIDER_EDIT,
    pageComponent: ProviderEdit,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Lieux de formation
  {
    label: 'Lieux de formation',
    routeUrl: RouteEnum.ADMIN_TRAINING_PLACE,
    pageComponent: TrainingPlaceListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Lieux de formation - Détail',
    routeUrl: RouteEnum.ADMIN_TRAINING_PLACE_SHOW,
    pageComponent: LocationShow,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Lieux de formation - Ajout',
    routeUrl: RouteEnum.ADMIN_TRAINING_PLACE_ADD,
    pageComponent: LocationForm,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Lieux de formation - Edition',
    routeUrl: RouteEnum.ADMIN_TRAINING_PLACE_EDIT,
    pageComponent: LocationUpdate,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Catégories
  {
    label: 'Catégories',
    routeUrl: RouteEnum.ADMIN_CATEGORY,
    pageComponent: CategoryListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Catégories - Détail',
    routeUrl: RouteEnum.ADMIN_CATEGORY_SHOW,
    pageComponent: CategoryPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Catégories - Ajout',
    routeUrl: RouteEnum.ADMIN_CATEGORY_ADD,
    pageComponent: CategoryEditAddPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Catégories - Edition',
    routeUrl: RouteEnum.ADMIN_CATEGORY_EDIT,
    pageComponent: CategoryEditAddPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Besoins en formation
  {
    label: 'Besoins en formation',
    routeUrl: RouteEnum.ADMIN_TRAINING_REQUEST,
    pageComponent: TrainingRequest,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Thématiques
  {
    label: 'Thématiques',
    routeUrl: RouteEnum.ADMIN_THEMATIC,
    pageComponent: ThematicListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },

  {
    label: 'Admin - Thématiques - Détail',
    routeUrl: RouteEnum.ADMIN_THEMATIC_SHOW,
    pageComponent: ThematicAdminPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Thématiques - Ajout',
    routeUrl: RouteEnum.ADMIN_THEMATIC_ADD,
    pageComponent: ThematicEditAddPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Thématiques - Edition',
    routeUrl: RouteEnum.ADMIN_THEMATIC_EDIT,
    pageComponent: ThematicEditAddPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Compétences
  {
    label: 'Compétences',
    routeUrl: RouteEnum.ADMIN_SKILL,
    pageComponent: SkillListPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Compétences - Détail',
    routeUrl: RouteEnum.ADMIN_SKILL_SHOW,
    pageComponent: Competence,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Compétences - Ajout',
    routeUrl: RouteEnum.ADMIN_SKILL_ADD,
    pageComponent: Createcompetence,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Compétences - Edition',
    routeUrl: RouteEnum.ADMIN_SKILL_EDIT,
    pageComponent: Updatecompetence,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  // Webinars
  {
    label: 'Webinaires',
    routeUrl: RouteEnum.ADMIN_WEBINAR,
    pageComponent: Webinar,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Webinars - Détail',
    routeUrl: RouteEnum.ADMIN_WEBINAR_SHOW,
    pageComponent: DetailWebinar,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Webinars - Ajout',
    routeUrl: RouteEnum.ADMIN_WEBINAR_ADD,
    pageComponent: CreateWebinair,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Admin - Webinars - Edition',
    routeUrl: RouteEnum.ADMIN_WEBINAR_EDIT,
    pageComponent: UpdateWebinair1,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Utilisateurs',
    routeUrl: RouteEnum.ADMIN_USERS,
    pageComponent: User,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  {
    label: 'Paramètres',
    routeUrl: RouteEnum.ADMIN_SETTINGS,
    pageComponent: UpdateSettings,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
    ],
  },
  /* ----------------- *
    *        FRONT       *
    * ------------------ */
  {
    label: 'Home',
    routeUrl: RouteEnum.HOME,
    pageComponent: HomePage,
    rolesAccess: [],
  },
  {
    label: 'Maintenance',
    routeUrl: RouteEnum.MAINTENANCE,
    pageComponent: MaintenancePage,
  },
  {
    label: 'Tableau de bord',
    routeUrl: RouteEnum.DASHBOARD,
    pageComponent: DashboardPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_ASSOCIE,
    ],
  },
  {
    label: 'Documents',
    routeUrl: RouteEnum.DOCUMENTS,
    pageComponent: Email,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'E-Learning',
    routeUrl: RouteEnum.E_LEARNING,
    pageComponent: ELearningPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'E-Learning - Détail',
    routeUrl: RouteEnum.E_LEARNING_DETAIL,
    pageComponent: DetailsFormationE,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Présentiel',
    routeUrl: RouteEnum.PRESENTIEL,
    pageComponent: OnSitePage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Présentiel - Détail',
    routeUrl: RouteEnum.PRESENTIEL_DETAIL,
    pageComponent: DetailFormation,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Thématique - List',
    routeUrl: RouteEnum.THEMATIC_LIST,
    pageComponent: ThematicPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Webinar - Détail',
    routeUrl: RouteEnum.WEBINAR_DETAIL,
    pageComponent: DetailsWebinar,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Profile',
    routeUrl: RouteEnum.PROFILE,
    pageComponent: ProfilePage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Recherche',
    routeUrl: RouteEnum.SEARCH,
    pageComponent: SearchPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Guy-A',
    routeUrl: RouteEnum.BOT,
    pageComponent: BotPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Formations démarrés',
    routeUrl: RouteEnum.STARTED,
    pageComponent: ThematicStartedPage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
  {
    label: 'Pour votre profil',
    routeUrl: RouteEnum.MY_PROFILE,
    pageComponent: ThematicProfilePage,
    rolesAccess: [
      RoleUserEnum.ROLE_SUPER_ADMIN,
      RoleUserEnum.ROLE_AGENT_IMMOBILIER,
      RoleUserEnum.ROLE_ASSOCIE,
      RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
      RoleUserEnum.ROLE_GESTIONNAIRE,
      RoleUserEnum.ROLE_CONSEILLER_VENTE,
      RoleUserEnum.ROLE_CONSEILLER_LOCATION,
      RoleUserEnum.ROLE_AGENT_COMMERCIAL,
      RoleUserEnum.ROLE_ASSISTANT_COMMERCIAL,
      RoleUserEnum.ROLE_PROSPECT,
      RoleUserEnum.ROLE_ALTERNANT,
      RoleUserEnum.ROLE_STAGIAIRE,
    ],
  },
];
